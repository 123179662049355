import React from 'react';
import classnames from 'classnames';

import "./Pancake.scss";

const Pancake = props => {
  const {children, className, id} = props;

  const theme = props.theme ? `PancakeTheme${props.theme}` : null;

  return (
    <section className={classnames('Pancake', className, theme)} id={id}>
      {children}
    </section>
  );
}

export default Pancake;
