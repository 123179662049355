import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-plugin-sanity-image";
import classnames from 'classnames';

import PageLayout from "../layouts/Page";
import Hero from "../components/Hero";
import Card from "../components/Card";
import Container from "../components/Container";
import Pancake from "../components/pancakes/Pancake"
import Button from "../components/Button"

import "./customers.scss";

const SchedulePage = props => {
  const { location } = props;

  const CustomersPageQuery = useStaticQuery(graphql`
    query CustomersQuery {
      allSanityCustomer {
        nodes {
          customers {
            website
            name
            logoFullColorDark {
              ...ImageWithPreview
            }
            logoMonochromeDark {
              asset {
                url
              }
            }
          }
          hero {
            heroText
            heroTitle
            heroOverlappingImage {
              altText
              image {
                ...ImageWithPreview
              }
            }
            heroCallToActions {
              _rawCallToActions
            }
            heroLogo {
              companyLogo {
                logoFullColorLight {
                  asset {
                    url
                  }
                }
                logoMonochromeLight {
                  asset {
                    url
                  }
                }
              }
              customLogo {
                altText
                image {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
      allSanityCaseStudy {
        nodes {
          company {
            name
          }
          excerpt
          slug {
            current
          }
        }
      }
    }
  `);

  const CustomersPage = CustomersPageQuery.allSanityCustomer.nodes[0];
  const CustomersCaseStudies = CustomersPageQuery.allSanityCaseStudy.nodes;

  const CustomersCards = CustomersPage.customers.map(customer => {
    /* Merge the Case Study object inside our customer card if a case study exists */
    const caseStudy = CustomersCaseStudies.find(caseStudy => caseStudy.company.name === customer.name);

    return {...customer, ...caseStudy}
  })

  return (
    <div className="SchedulePage">
      <PageLayout 
        location={location} title="Customers" 
        description=""
      >
        <Hero>
          {CustomersPage.hero?.heroTitle && <Hero.Title>{CustomersPage.hero.heroTitle}</Hero.Title>}
          {CustomersPage.hero?.heroText && <Hero.Text>{CustomersPage.hero.heroText}</Hero.Text>}
        </Hero>
        <Pancake>
          <Container>
            <div className="CustomersGrid">
              {CustomersCards.map(customer => {
                const isCaseStudyCard = customer.slug?.current ? true : false;
                return (
                  <Card clickable={true} theme={isCaseStudyCard ? "Light" : null} key={customer.name} paddingSize="Double" shadowLevel={isCaseStudyCard ? "Normal" : "Low"} className={classnames(isCaseStudyCard ? "CardCaseStudy" : "CardLogo")}>
                    {customer.logoFullColorDark &&
                      <Card.Image>
                        <Image {...customer.logoFullColorDark} alt="" />
                      </Card.Image>
                    }
                    {!customer.logoFullColorDark &&
                      <Card.Title>
                        {customer.name}
                      </Card.Title>
                    }
                    {customer.excerpt &&
                      <Card.Content>
                        <p>{customer.excerpt}</p>
                      </Card.Content>
                    }
                    {isCaseStudyCard &&
                      <Card.CTA>
                        <Button className="corporate-light transparent full" to={`/customers/${customer.slug.current}`} arrow>Case Study</Button>
                      </Card.CTA>
                    }
                    {!isCaseStudyCard &&
                      <Card.CTA>
                        <Button className="corporate-light transparent block" to={customer.website}></Button>
                      </Card.CTA>
                    }
                  </Card>
                )
              })}
            </div>
          </Container>
        </Pancake>
      </PageLayout>
    </div>
  );
};

export default SchedulePage;
